<template  src="./main.page.pug" lang="pug"></template>

<script>
import performance from '../../Performance/performance'

export default {
	name: 'main.page.vue',
	components: {
		performance
	}
}
</script>

<style scoped>

</style>
